.header-top {
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px 20px;
    font-size: 0.9rem;
    min-height: 45px;
}

    .header-top a {
        display: flex;
        align-items: center;
    }

    .header-top .actions {
        display:flex;
        align-items:flex-end;
    }


    .header-top .inscription {
        font-weight: bold;
        display: flex;
        align-items: center;
        border: 1px solid #325288;
        background-color: #325288;
        color: white;
        cursor: pointer;
        text-decoration: none;
        padding: 1px 10px;
        border-radius: 3px;
        white-space: nowrap;
        transition: all 0.2s ease-out;
        margin: 5px auto 5px 8px;
    }

        .header-top .inscription:hover {
            background-color: white;
            color: #325288;
            border: 1px solid #325288;
        }


    .header-top .login {
        font-weight: bold;
        display: flex;
        align-items: center;
        color: #325288;
        cursor: pointer;
        text-decoration: none;
        padding-left:20px;
        font-variant:small-caps;
    }

        .header-top .login:hover {
            text-decoration: underline;
        }

        .header-top .login svg {
            padding-left: 3px;
            padding-right: 3px;
        }


    .header-top > .disconnect {
        font-weight: bold;
        display: flex;
        align-items: center;
        color: #808080;
        cursor: pointer;
        text-decoration: none;
        padding-left: 20px;
        font-variant: small-caps;
        margin-right:auto;
    }


        .header-top > .disconnect:hover {
            text-decoration: underline;
        }