

.menu-admin {
    max-width: 240px;
    min-width: 240px;
    background-color: #3d454d;
    color: #fff;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

    .menu-admin .acces {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 0.9rem;
        line-height: 1.6;
    }

    .menu-admin .fiche-courante {
        text-align: center;
    }

    .menu-admin .fiche-label {
        display: flex;
        align-items: center;
        justify-content: center;
    }

        .menu-admin .fiche-label span {
            font-size: 0.8rem;
            color: white;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
            text-decoration: none;
            padding: 4px 20px;
        }

    .menu-admin .fiche-switch {
        color: white;
        background-color: #ff6a00;
        width:60px;
        border-radius:5px;
        display:flex;
        align-items:center;
        justify-content:center;
        padding:5px;
        margin:10px auto;
    }

        .menu-admin .fiche-switch svg {
            font-size: 1.2rem;
            color: white;
        }




    .menu-admin .disconnet {
        min-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #314f81;
        padding-top: 5px;
        padding-bottom: 5px;
        margin: 10px 0 20px 0;
        font-weight: bold;
        color: white;
        text-decoration: none;
        font-size: 0.9rem;
    }

    .menu-admin > .disconnet svg {
        padding-right: 5px;
    }

    .menu-admin > .disconnet:hover {
        background-color: #233b62;
    }

    .menu-admin .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 50px 10px 50px;
    }

        .menu-admin .image-container .logo {
            max-width: 100%;
        }

    .menu-admin .menu-item {
        color: white;
        padding: 5px 10px 5px 20px;
        text-decoration: none;
        font-weight: bold;
        font-size: 0.95rem;
    }

        .menu-admin .menu-item.active {
            color: #b4b4b4;
        }
