.search-bar-container {
    grid-area: rigth;
    display: flex;
    justify-content: flex-end;
}

.search-bar {
    display: flex;
    align-items: center;
    background-color:#325288;
    flex-wrap: nowrap;
    border: 1px solid white;
    border-radius: 6px;
    margin: 2px 20px;
    flex-grow: 1;
    overflow: hidden;
    transition: all 0.2s ease-out;
}
    .search-bar.small {
        width:300px;
    }
    .search-bar.full {
        flex-grow:1;
    }

    .search-bar svg {
        color: white;
        cursor: pointer;
        font-size: 1.2rem;
        min-width: 80px;
        min-height: 24px;
    }

    .search-bar input {
        padding: 0 20px;
    }

