.header-mobile {
    display: flex;
    flex-direction: column;
    user-select: none;
    border-bottom: 1px solid #e4e4e4;
    box-shadow: 0 -15px 35px rgb(0 0 0 / 50%);
    z-index: 100;
}

    .header-mobile .menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

        .header-mobile .menu a {
            display: flex;
            align-items: center;
        }

        .header-mobile .menu svg {
            font-size: 2.5rem;
            padding: 0.6rem 0.8rem;
            color: #325288;
            cursor: pointer;
        }

        .header-mobile .menu .logo {
            max-width: 6rem;
            padding: 0.4rem 1.2rem;
        }

    .header-mobile .content {
        border-top: 1px solid #d9d9d9;
        display: flex;
        flex-direction: column;
    }

        .header-mobile .content .item {
            padding: 0.3rem 2rem;
            display: flex;
            align-items: center;
            color: #0c8ad3;
            font-weight: bold;
            text-decoration: none;
            font-size: 1.1rem;
            background-color: #eee;
            border-bottom: 1px solid white;
        }

            .header-mobile .content .item svg {
                color: #313131;
                padding-right: 0.6rem;
                font-size: 1rem;
            }
