
body {
    height: 100vh;
}


#root {
    height: 100vh;
}

#layout-user {
    display: flex;
    min-height: 100vh;
}

    #layout-user .title-lock {
        margin-top: 200px;
        font-size: 1.8rem;
        font-weight: bold;
        color: #ff0000;
    }


    #layout-user .sub-title-lock {
        font-size: 1.4rem;
    }