.header-bottom {
    display: grid;
    grid-template-columns: 20% 40% 40%;
    grid-template-areas: ". center rigth";
    background-color: #314f81;
    border-bottom: 1px solid #284169;
}


    .header-bottom .logo {
        max-width: 6rem;
        display: flex;
        align-items: center;
        padding: 10px 40px;
    }

    .header-bottom .search-container {
        margin-top: auto;
        margin-bottom: auto;
        min-width: 100%;
    }


#menu-deroulant, #menu-deroulant ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

#menu-deroulant {
    grid-area: center;
    padding-left: 20px;
}

    #menu-deroulant li {
        display: inline-block;
    }

    #menu-deroulant ul li {
        display: inherit;
        border-radius: 2px;
    }

    #menu-deroulant a {
        text-decoration: none;
        display: block;
        font-variant: small-caps;
        color: white;
        padding: 14px 15px;
        cursor: pointer;
        font-size: 1rem;
        transition: all 0.2s ease-out;
    }

        #menu-deroulant a:hover {
            color: #adadad;
        }

    #menu-deroulant .menu-item {
        display: flex;
        align-items: center;
    }

        #menu-deroulant .menu-item svg {
            color: #325288;
            padding-left: 10px;
            padding-right: 6px;
        }

    #menu-deroulant li ul li a {
        background-color: white;
        /**** sub item style ****/
        color: #383838;
        font-size: 0.9rem;
        padding: 10px 0;
        width: 200px;
        transition: all 0.2s linear;
    }

        #menu-deroulant li ul li a:hover {
            font-weight: bold;
            background-color: #F5F5F5;
        }

    #menu-deroulant li ul li:first-child a {
        border-top: 1px solid #EEEEEE;
    }

    #menu-deroulant li ul li:last-child a {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    #menu-deroulant ul {
        position: absolute;
        /* on cache les sous menus complètement sur la gauche */
        left: -999em;
        text-align: left;
        z-index: 1000;
    }

    #menu-deroulant li:hover ul {
        /* Au survol des li du menu on replace les sous menus */
        left: auto;
    }

.menu-deroulant-liste {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border-top: 3px solid #325288;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
