

.footer {
    background-color: #1a2024;
    display: flex;
    flex-direction: column;
    padding:40px;
}

    .footer h3 {
        font-variant:small-caps;
        padding-bottom:10px;
    }

    .footer .content {
        display: flex;
        flex-wrap: wrap;
        color: #AAAAAA;
        justify-content:space-evenly;
    }

        .footer .content > div {
            display: flex;
            flex-direction: column;
            min-width:200px;
        }


    .footer .footer-item {
        padding: 2px 20px;
        color: white;
        font-weight: bold;
        text-decoration: none;
        font-size:0.8rem;
    }


    .footer .inscription {
        font-weight: bold;
        text-align:center;
        border: 1px solid #325288;
        background-color: #325288;
        color: white;
        cursor: pointer;
        text-decoration: none;
        padding: 4px 20px;
        border-radius: 3px;
        white-space: nowrap;
        transition: all 0.2s ease-out;
        max-width: 320px;
        min-width: 320px;
    }

        .footer .inscription:hover {
            background-color: white;
            color: #325288;
            border: 1px solid #325288;
        }


    .footer .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top:50px;
    }


    .footer .rights {
        padding:20px;
        display:flex;
        align-items:center;
        justify-content:center;
        color:white;
    }